<template lang="pug">
mixin searchInput
  om-input#subAccountSearch(
    v-model="searchInput"
    small
    @input="lazySearch"
    :placeholder="$t('search')"
    type="text"
  )

.brand-wrapper
  .container
    template(v-if="partnerTypeService.isResellerPartnerType() || partnerTypeService.isCustom()")
      h1.mb-0 {{ $t('welcomeUser', { firstName }) }}
    template(v-else)
      om-heading(h1) {{ $t('partnerDashboard') }}
    affiliate-summary.mt-6.mb-6(v-if="isAffiliate && isOwner" showRedirectLink :data="affiliate")
      template(slot="title")
        h3.mb-0 {{ $t('ambassadorSummary') }}
    template(v-if="!isManagedReferral && !hideSubAccountsTable")
      .row.mb-4.mt-5
        .col.d-flex.align-items-center
          h3.mb-0.mr-3 {{ $t('subAccounts') }}
          +searchInput
        .col.d-flex.justify-content-end
          om-button(primary @click.prevent="onReportClick") {{ $t('affiliate.sendReport') }}
          om-button.ml-2(primary @click="$modal.show('new-sub-account-modal')") {{ $t('newSubAccount') }}
      sub-account-table.account-table(
        :account="account"
        :filter="filter"
        @updateLimits="fetchAccount"
      )
    template(v-if="isAffiliate")
      .row.mb-4.mt-5
        .col.d-flex.align-items-center
          h3.mb-0.mr-3 {{ $t('managedAccounts') }}
          +searchInput
        .col.d-flex.justify-content-end
          om-button(primary @click="$modal.show('invite-affiliate')") {{ $t('inviteUsers') }}
      managed-accounts-table(:filter="filter")
    new-sub-account-modal(
      :agencyLimits="agencyLimits"
      :agencyAssignable="agencyAssignable"
      @refreshSubAccounts="$bus.$emit('refreshSubAccounts')"
    )
    invite-affiliate-modal(:partnerId="partnerId")
    agency-send-report
</template>

<script>
  import { debounce } from 'lodash-es';
  import AffiliateSummary from '@/components/Affiliate/AffiliateSummary';
  import InviteAffiliateModal from '@/components/Modals/InviteAffiliate.vue';
  import NewSubAccountModal from '@/components/Modals/NewSubAccount.vue';
  import ManagedAccountsTable from '@/components/Tables/ManagedAccountsTable.vue';
  import SubAccountTable from '@/components/Tables/SubAccountTable.vue';
  import GENERATE_SUB_ACCOUNT_USAGE_STATISTIC from '@/graphql/GenerateSubAccountUsageReport.gql';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import affiliateDataMixin from '@/mixins/affiliate-data';

  export default {
    components: {
      SubAccountTable,
      ManagedAccountsTable,
      NewSubAccountModal,
      InviteAffiliateModal,
      PieChart: () => import('@/components/Charts/PieChart'),
      AffiliateSummary,
      AgencySendReport: () => import('@/components/Modals/AgencySendReport.vue'),
    },
    mixins: [affiliateDataMixin],

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.fetchAccount();
        vm.setAffiliateData();
        vm.setPayoutItems();
      });
    },

    data() {
      return {
        primaryColor: '',
        searchInput: '',
        filter: { search: '' },
      };
    },

    computed: {
      ...mapState(['account', 'partnerTypeService']),
      ...mapGetters(['isAffiliate', 'isManagedReferral', 'isOwner']),
      firstName() {
        return this.$store.state.account.login.firstName;
      },
      agencyAssignable() {
        return this.agencyLimits.maxVisitor - this.account.totalAssigned;
      },
      agencyLimits() {
        return this.account.limits !== undefined ? this.account.limits : {};
      },
      hideSubAccountsTable() {
        if (this.partnerTypeService.isAgencyPartnerType()) {
          return true;
        }
        // Using old logic for custom partner type
        return (
          (this.partnerTypeService.isCustom() &&
            this.$store.state.account.settings.hideSubAccountsTable) ||
          false
        );
      },
      partnerId() {
        return this.affiliate.partnerData?.code;
      },
    },

    mounted() {
      setTimeout(() => {
        this.primaryColor = getComputedStyle(document.body).getPropertyValue(
          '--brand-primary-color',
        );
      }, 100);
    },

    methods: {
      ...mapActions(['fetchAccount']),
      lazySearch: debounce(function (event) {
        this.filter.search = event;
      }, 300),
      onReportClick() {
        this.$modal.show('agency-send-report');
      },
      async sendUsageReport() {
        try {
          const {
            data: { generateSubAccountsUsageReport: data },
          } = await this.$apollo.query({
            query: GENERATE_SUB_ACCOUNT_USAGE_STATISTIC,
            variables: {
              databaseId: this.account.databaseId,
            },
          });
          if (data.success) {
            return this.$notify({
              type: 'success',
              text: this.$t('notifications.sendSuccess'),
            });
          }
          if (!data.success && data.errorCode === 'LIMIT_REACHED') {
            return this.$notify({
              type: 'error',
              title: this.$t('notifications.limitReached'),
            });
          }
        } catch (e) {
          return this.$notify({
            type: 'error',
            title: this.$t('notifications.unsuccessfulUsageReport'),
          });
        }
      },
    },
  };
</script>
